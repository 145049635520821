<template>
  <!-- counter -->
  <div>
    <v-container grid-list-lg class="carder" fluid>
      <v-row>
        <v-col cols="4">
          <v-text-field
            outlined
            dense
            rounded
            class="ma-3"
            color="blue"
            label="Search Name/Identifier/Owner Name/Address/Postcode "
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            @change="findMerchant"
          ></v-text-field>
        </v-col>
        <v-col cols="4"></v-col>
        <v-col cols="2">
          <!-- <v-select
            :items="items"
            label="Filter By"
            class="ma-3"
            color="light-blue"
            dense
            rounded
            outlined
          ></v-select> -->
        </v-col>
        <v-col cols="2">
          <v-dialog transition="dialog-top-transition" max-width="600">
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="blue"
                class="ma-3 pa-5"
                v-bind="attrs"
                v-on="on"
                dense
                rounded
              >
                <v-icon>mdi-plus</v-icon>
                <span>New</span>
              </v-btn>
            </template> -->
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="blue" dark>New Merchant</v-toolbar>
                <v-card-text>
                  <v-container grid-list-lg>
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Name"
                          dense
                          outlined
                          color="info"
                        ></v-text-field>
                        <v-text-field
                          label="Contact No"
                          dense
                          outlined
                          color="info"
                        ></v-text-field>
                        <v-select
                          :items="getSystem"
                          label="System"
                          item-color="info"
                          color="light-blue"
                          dense
                          outlined
                        ></v-select>
                        <v-select
                          :items="getRole"
                          label="Subscription"
                          item-color="info"
                          color="light-blue"
                          dense
                          outlined
                        ></v-select>
                        <v-select
                          :items="status"
                          label="Status"
                          item-color="info"
                          color="light-blue"
                          dense
                          outlined
                        ></v-select>
                        <div>
                          <p class="font-weight-bold">Expire by</p>
                          <v-date-picker
                            v-model="user.date"
                            :landscape="true"
                            color="info"
                          ></v-date-picker>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                  <v-btn text @click="dialog.value = false">Save</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
      </v-row>
      <v-simple-table class="table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Business Name</th>
              <th class="text-left">Shop Identifier</th>
              <th class="text-left">POS Integration</th>
              <th class="text-left">Expire By</th>
              <th class="text-left">Subscription</th>
              <th class="text-left">Status</th>
              <th class="text-left">Version</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in desserts.merchants" :key="i">
              <td>{{ item.name }}</td>
              <td>
                <v-btn color="white" block @click="openShop(item.short_id, item.v2 ? 'V2' : item.ejen2u_merchant_id ? 'Ejen2u' : 'V1' )"
                  >{{ item.short_id || '-' }}
                </v-btn>
              </td>
              <td>
                {{ item.outlets ? "Yes" : item.outlets > 0 ? "Yes" : "No" }}
              </td>
              <td>
                <span>{{ returnDate(item.expired_at) || "-" }}</span>
              </td>
              <td>
                <v-chip class="ma-2" :color="returnIcon('', 'Basic')" outlined>
                  <v-icon left>{{ returnIcon("icon", "Basic") }}</v-icon>
                  {{
                    !item.v2
                      ? "Slurp Plan"
                      : item.v2 && item.plan
                      ? item.plan.name
                      : "Trial"
                  }}
                </v-chip>
              </td>
              <td>
                <v-icon :color="item.offline == false ? 'green' : 'red'"
                  >mdi-circle-medium</v-icon
                ><span
                  :class="
                    item.offline == false
                      ? 'font-weight-bold green--text'
                      : 'font-weight-bold red--text'
                  "
                  >{{
                    item.offline == false ? "Shop Open" : "Shop Close"
                  }}</span
                >
              </td>
              <td>{{ item.v2 ? "V2" : item.ejen2u_merchant_id ? "Ejen2u" : "V1" }}</td>
              <td>
                <v-btn color="success" block @click="getToken(item, 'v1')"
                  >Impersonate</v-btn
                >
                <div v-if="item.sandbox && (!item.ejen2u_merchant_id && item.ejen2u_merchant_id != '')">
                  <v-btn color="success" block @click="getToken(item, 'v2')"
                  >Impersonate Staging</v-btn
                >
                </div>
                <div v-if="item.ejen2u_merchant_id && item.ejen2u_merchant_id != ''">
                  <v-btn color="success" block @click="getToken(item, 'e2u')"
                  >Impersonate Ejen2u</v-btn
                >
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="mt-4">
        Total Results : {{ this.desserts.merchants.length }} /
        {{ this.desserts.count }}
      </div>
    </v-container>
    <div class="mt-6">
      <div class="text-center">
        <v-pagination
          v-model="page"
          color="blue darken"
          :length="length"
          :total-visible="7"
          @input="turnPage"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
export default {
  name: "Merchants",

  data() {
    return {
      length: 50,
      page: 1,
      search: "",
      user: {
        name: "",
        system: "",
        phone: "",
        role: "",
        status: "",
        date: "",
      },
      systems: [
        { name: "GetOrders", color: "red darken-1 white--text" },
        { name: "Uniplacement", color: "purple darken-1 white--text" },
      ],
      items: ["Name", "Expire By", "Contact no", "System", "Status"],
      status: ["Ongoing", "Expired", "Inactive"],
      subs: [
        {
          name: "Basic",
          icon: "",
          color: "blue-grey",
        },
        {
          name: "Premium",
          icon: "",
          color: "primary",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      desserts: "getMerchantList",
      impersonateToken: "getToken",
    }),

    getRole() {
      return this.subs.map((d) => d.name);
    },

    getSystem() {
      return this.systems.map((d) => d.name);
    },
  },

  methods: {
    returnIcon(ident, value) {
      //   console.log(this.roles);
      if (ident == "icon") {
        const icon_name = this.subs.find((item) => item.name == value).icon;
        return icon_name;
      } else {
        const icon_name = this.subs.find((item) => item.name == value).color;
        return icon_name;
      }
    },

    getOut(str, i) {
      console.log(str, i);
    },

    returnDate(i) {
      return dayjs(i).format("DD/MM/YY h:mmA");
    },

    getToken(v, stat) {
      this.$store.dispatch("startLoad");
      this.$store.dispatch("generateToken", v.fire_id).then((k) => {
        // console.log("k : ", k.data.token);
        window.open(stat == 'v2' ? "https://cloud.staging.getorders.app/#/token=" + k.data.token : stat == 'v1' ? "https://cloud.getorders.app/#/token=" + k.data.token : "https://cloud-getorders-ejen2u.surge.sh/#/token=" + k.data.token, "_blank");
      });
    },

    openShop(short_id, type) {
      window.open(type == 'V2'? "https://v2.getorders.app/" + short_id : "https://getorders.app/#" + short_id, "_blank");
    },

    impersonate(v) {
      this.$store.dispatch("startLoad");
      this.$store.dispatch("generateToken", v.fire_id);
    },

    turnPage(i) {
      // console.log(i);
      this.$store.dispatch("startLoad");
      this.$store.dispatch("fetchMerchants", { page: i - 1 });
    },

    returnlength() {
      let counts = parseFloat(this.desserts.count / 15).toFixed(0);
      // console.log(this.desserts);
      this.length = Number(counts);
      return counts;
    },

    findMerchant(value) {
      // console.log(value);
      this.$store.dispatch("startLoad");
      this.$store.dispatch("searchMerchant", value);
    },
  },

  beforeMount() {
    this.$store.dispatch("startLoad");
    this.$store.dispatch("fetchMerchants", { page: 0 }).then(() => {
      this.returnlength();
    });
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.pad {
  margin-top: 3px;
}

.w-75 {
  width: 75%;
}
.circle {
  height: 5%;
  width: 5%;
  border: 1px solid black;
  background-color: black;
  border-radius: 25%;
}

.table {
  height: 70vh;
  overflow-y: auto;
}
</style>
